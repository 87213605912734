<template>
  <div class="home">
    <!--    <elMenu></elMenu>-->
    <img src="../../../public/images/veecam/sdk/download.jpg" width="100%">
    <div>
      <div class="join_tile">
        开放接口
      </div>
      <p style="text-align:center">丰富的解决方案为您的业务助力</p>
    </div>
    <div>
      <el-tabs type="border-card">
        <el-tab-pane label="Java SDK">文档待上传，敬请留意。
        </el-tab-pane>
        <el-tab-pane label="Python SDK">文档待上传，敬请留意。</el-tab-pane>
        <el-tab-pane label="PHP SDK">文档待上传，敬请留意。</el-tab-pane>
        <el-tab-pane label="IOS SDK">文档待上传，敬请留意。</el-tab-pane>
      </el-tabs>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "sdk",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>
.join_tile {
  margin-top: 72px;
  text-align: center;
  font-size: 24px;
}

/deep/ .el-tabs--border-card {
  height: 420px;
}
</style>